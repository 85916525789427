import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function ConsentForm() {
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Consent Form
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={24} sm={12}>
                    <Typography variant="body2" gutterBottom>
                        I, the undersigned, hereby authorize 'Moove Africa' to which I have applied for a position  (“The Company”), to obtain a pre-partnership background screening report on me in order to verify my particulars as in the documents provided  for the purpose of my application by and through the Company’s service provider, Peleza International Limited hereinafter referred to as Peleza, in accordance with the Company’s Data Privacy and Peleza Privacy Policy.
                    </Typography>
                    <br />
                    <Typography variant="body2" gutterBottom>
                        The above mentioned report will include job related information, such as Criminal record, Driving License, PSV License, National Identity and other applicable public record information.
                        I further authorize the Company and Peleza to disclose a copy of this authorization to any person(s), educational establishment(s), former employer(s), business entity(ies) or public body(s) (“Third Party”) shown on my curriculum vitae and self-declaration form and for this authorization to act as my specific consent to any such Third Party for the disclosure to the Company/ Peleza of any personal data about me.
                    </Typography>
                    <br />
                    <Typography variant="body2" gutterBottom>
                        I hereby release the Company, Peleza and any Third Parties from any claims, costs, expenses or rights of action of any kind whatsoever (“Claims”) which I (or others on my behalf) may have against the Company or Peleza arising directly or indirectly out of or in connection with the provision of the pre-employment background screening report, save where such Claim arises directly as a result of the gross negligence or intent of the Company or Peleza. This authorization given in this Consent Form expires automatically if my application is rejected or following termination of my partnership with the Company in the event that my application is successful.
                    </Typography>
                    <br />
                    <Typography variant="body2" gutterBottom>
                        I hereby release the Company, Peleza and any Third Parties from any claims, costs, expenses or rights of action of any kind whatsoever (“Claims”) which I (or others on my behalf) may have against the Company or Peleza arising directly or indirectly out of or in connection with the provision of the pre-employment background screening report, save where such Claim arises directly as a result of the gross negligence or intent of the Company or Peleza. This authorization given in this Consent Form expires automatically if my application is rejected or following termination of my partnership with the Company in the event that my application is successful.
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}