import React from 'react';
import { Field, reduxForm } from 'redux-form';
// import { ProjectInput } from './ProjectInput';
// import { ProjectTextArea } from './ProjectTextArea';
import { ImgFileUpload } from './ImgFileUpload';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import RFTextField from '../LandingPage/modules/form/RFTextField';
import FormButton from '../LandingPage/modules/form/FormButton';

class DriverDetailsForm extends React.Component {
    state = {
        imageState: false,
        imageState2: false,
        imageState3: false,

    }
    render() {
        const {
            handleSubmit,
            pristine,
            submitting,
            submitCb,
            valid,
            SetImage,
            SetImage2,
            SetImage3,
            loading
        } = this.props;
        return (
            <Box noValidate sx={{ mt: 6 }}>
                <form onSubmit={handleSubmit(submitCb).bind(this)}
                    onClick={this.resetValues}>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Field
                                autoFocus
                                component={RFTextField}
                                disabled={loading}
                                autoComplete="given-name"
                                fullWidth
                                name="firstName"
                                label='Driver Firstname'
                                type="text"
                                className='form-control'
                            // component={ProjectInput}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
                                component={RFTextField}
                                disabled={loading}
                                autoComplete="family-name"
                                fullWidth
                                label='Driver Lastname'
                                name="lastName"
                                type="text"
                                className='form-control'
                            // component={ProjectInput}
                            />
                        </Grid>
                    </Grid>
                    <Field
                        component={RFTextField}
                        disabled={loading}
                        fullWidth
                        label="Date of submission (E.g: dd/mm/yyyy)"
                        margin="normal"
                        name="submissionDate"
                        type="text"
                        className='form-control'
                    // component={ProjectInput}
                    />
                    <Field
                        component={RFTextField}
                        disabled={loading}
                        fullWidth
                        label='ID number'
                        margin="normal"
                        name="idNumber"
                        type="text"
                        className='form-control'
                    // component={ProjectInput}
                    />
                    <Field
                        component={RFTextField}
                        disabled={loading}
                        fullWidth
                        label='Phone number'
                        margin="normal"
                        name="phoneNumber"
                        type="text"
                        className='form-control'
                    // component={ProjectInput}
                    />
                    <Field
                        component={RFTextField}
                        disabled={loading}
                        fullWidth
                        label='Email Address'
                        margin="normal"
                        name="email"
                        type="email"
                        className='form-control'
                    // component={ProjectInput}
                    />

                    <Field
                        component={RFTextField}
                        disabled={loading}
                        fullWidth
                        label="Mpesa Transaction Code (Eg: PLK1234567TY)"
                        margin="normal"
                        name="mpesaCode"
                        type="text"
                        className='form-control'
                    // component={ProjectInput}
                    />
                    <label style={{ color: "grey" }}><small>Pay Screening Fee of KES 2000 via Peleza International Paybill 673496. Account Number is driver National ID Number</small></label>

                    <br />
                    <br />

                    <hr />

                    <label>
                        <strong>
                            Upload Passport Photo:
                        </strong>
                    </label>
                    <br />
                    <label style={{ color: "GrayText" }} ><small>(Upload your passport photo here*)</small></label>
                    <br />
                    <br />
                    <Field
                        name="passportPhoto"
                        label='Upload Passport Photo'
                        className='form-control'
                        component={ImgFileUpload}
                        props={{
                            changedImage: (e) => {
                                SetImage(e);
                                this.setState({
                                    imageState: true
                                })
                            },
                            checkImageState: (e) => {
                                if (e === 'selected') {
                                    this.setState({
                                        imageState: true
                                    });
                                } else {
                                    this.setState({
                                        imageState: false
                                    });
                                }
                            },
                        }}
                        key={this.props.key}
                    />
                    <hr />


                    <label>
                        <strong>
                            Upload ID Photo:
                        </strong>
                    </label>
                    <br />
                    <label style={{ color: "GrayText" }} ><small>(Take a clear photo of your National ID and upload it here*)</small></label>
                    <br />
                    <br />
                    <Field
                        name="idPhoto"
                        label='Upload Passport Photo'
                        className='form-control'
                        component={ImgFileUpload}
                        props={{
                            changedImage: (e) => {
                                SetImage2(e);
                                this.setState({
                                    imageState2: true
                                })
                            },
                            checkImageState: (e) => {
                                if (e === 'selected') {
                                    this.setState({
                                        imageState2: true
                                    });
                                } else {
                                    this.setState({
                                        imageState2: false
                                    });
                                }
                            },
                        }}
                        key={this.props.key}
                    />


                    <hr />
                    <label>
                        <strong>
                            Upload Certificate of Good Conduct:
                        </strong>
                    </label>
                    <br />
                    <label style={{ color: "GrayText"}} ><small>(Take a clear photo of your Certificate of Good Conduct and upload it here, not older than 1 year*)</small></label>
                    <br />
                    <br />
                    <Field
                        name="goodConductCertPhoto"
                        label='Upload Certificate of Good Conduct'
                        className='form-control'
                        component={ImgFileUpload}
                        props={{
                            changedImage: (e) => {
                                SetImage3(e);
                                this.setState({
                                    imageState3: true
                                })
                            },
                            checkImageState: (e) => {
                                if (e === 'selected') {
                                    this.setState({
                                        imageState3: true
                                    });
                                } else {
                                    this.setState({
                                        imageState3: false
                                    });
                                }
                            },
                        }}
                        key={this.props.key}
                    />
                    <hr />
         
                    {
                        loading ?
                            <FormButton
                                variant="contained"
                                type="button"
                                onClick={this.handleNext}
                                sx={{ mt: 3, mb: 2 }}
                                disabled={true}
                                fullWidth
                            >
                                Sending...
                            </FormButton>
                            // <button
                            //     className='btn btn-primary'
                            //     type="button"
                            //     disabled={true}
                            // >
                            //     Sending...
                            // </button>
                            :
                            <FormButton
                                variant="contained"
                                type="submit"
                                onClick={this.handleNext}
                                sx={{ mt: 3, mb: 2  }}
                                fullWidth
                                disabled={!valid || pristine || submitting || !this.state.imageState || !this.state.imageState2 || !this.state.imageState3}
                            >
                                Send
                            </FormButton>
                    }
                </form>
            </Box>
        )
    }
}
const validate = values => {
    const errors = {};
    if (!values.firstName) {
        errors.firstName = 'Driver firstname required!';
    }
    if (!values.lastName) {
        errors.lastName = 'Driver lastname required!';
    }
    if (!values.submissionDate) {
        errors.submissionDate = 'Submission Date required!';
    }
    if (!values.idNumber) {
        errors.idNumber = 'ID Number required!';
    }
    if (!values.phoneNumber) {
        errors.phoneNumber = 'Phone Number required!';
    }
    if (!values.email) {
        errors.email = 'Email Address required!';
    }
    if (!values.mpesaCode) {
        errors.mpesaCode = 'Mpesa Code required!';
    }
    // if (!values.title) {
    //     errors.title = 'Please enter title!';
    // }
    // if (!values.message) {
    //     errors.message = 'Please enter message!';
    // }
    return errors;
}
export default reduxForm({
    form: 'DriverDetailsForm',
    validate
})(DriverDetailsForm)