import * as React from 'react';
// import axios from 'axios';
// import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
// import { Field, Form, FormSpy } from 'react-final-form';
import Typography from './modules/components/Typography';
import AppFooter from './modules/views/AppFooter';
import AppAppBar from './modules/views/AppAppBar';
import AppForm from './modules/views/AppForm';
// import { email, required } from './modules/form/validation';
// import RFTextField from './modules/form/RFTextField';
// import FormButton from './modules/form/FormButton';
// import FormFeedback from './modules/form/FormFeedback';
import withRoot from './modules/withRoot';
// import { useNavigate } from 'react-router-dom';
import DriverDetailsForm from '../Components/DriverDetailsForm';
import { reset } from 'redux-form';
import axios from 'axios';
import { connect } from "react-redux";

class ApplicationFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      note: '',
      loading: false,
    }
    this.pristine = false;
    this.Send = this.Send.bind(this);
  }

  SetImage = async (image) => {
    await this.setState({ image });
  };

  SetImage2 = async (image2) => {
    await this.setState({ image2 });
  };

  SetImage3 = async (image3) => {
    await this.setState({ image3 });
  };

  DisplaySuccessAlert() {
    var newLine = "\r\n\n"
    var msg = "Details Sent Successfully!"
    msg += newLine;
    msg += "We will get back to you via email within 48 Hours.";
    msg += newLine;
    msg += "Best Regards,";
    msg += newLine;
    msg += "Peleza International.";
    alert(msg);
  }

  DisplayErrorAlert() {
    var newLine = "\r\n\n"
    var msg = "Sorry!! An Error Occurred!"
    msg += newLine;
    msg += "Kindly try again using smaller image sizes(kb), or contact the system administrator.";
    msg += newLine;
    msg += "Best Regards,";
    msg += newLine;
    msg += "Peleza International.";
    alert(msg);
  }

  Send(userData) {
    let { image, image2, image3 } = this.state;
    userData = { ...userData, image, image2, image3 };
    this.setState({ loading: true });
    this.sendEmail(userData).then(
      submited => {
        // toast.success('Email sent successfully');
        this.DisplaySuccessAlert()
        this.props.dispatch(reset('DriverDetailsForm'));
        this.setState({ key: 'cleared' })
        this.setState({ note: 'Email sent successfully', loading: false });
      },
    ).catch(errors => {
      // toast.error('Error occured')
      alert('An Error Occured, Kindly try again using smaller size images or contact the system administrator.')
      this.setState({ errors, loading: false })
    });
  };

  sendEmail = async emailData => {
    console.log(emailData);
    return axios.post("https://drivers.pidva.africa/api/v1/contact", emailData).then(
    // return axios.post(`${"http://localhost:3500/v1/contact" || "https://drivers.pidva.africa/api/v1/contact"}`, emailData).then(
      res => res.data,
      err => Promise.reject(err.response.data.errors)
    )
  };

  // function ApplicationFormPage() {
  // const[sent, setSent] = React.useState(false);
  // const navigate = useNavigate();

  // const validate = (values) => {
  //   const errors = required(['firstName', 'lastName', 'submissionDate', 'idNumber', 'phoneNumber', 'email', 'mpesaTransactionCode'], values);

  //   if (!errors.email) {
  //     const emailError = email(values.email);
  //     if (emailError) {
  //       errors.email = emailError;
  //     }
  //   }

  //   return errors;
  // };

  // const handleSubmit = (values) => {
  //   setSent(true);

  //   const userData = { ...values };

  //   sendEmail(userData).then(
  //     submited => {
  //       // alert('Email Sent Successfully')
  //       // setSent(false);
  //       navigate('/application-sent-page')
  //     },
  //   ).catch(
  //     errors => {
  //       // alert('An Error Occured.')
  //       // setSent(false);
  //       navigate('/application-sent-fail-page')
  //     });

  // };

  // const sendEmail = async emailData => {
  //   console.log(emailData);
  //   return axios.post("https://drivers.pidva.africa/api/v1/contact", emailData).then(
  //     // return axios.post(`${"http://localhost:3500/v1/contact" || "https://drivers.pidva.africa/api/v1/contact"}`, emailData).then(
  //     res => res.data,
  //     err => Promise.reject(err.response.data.errors)
  //   )
  // };

  render() {
    const { errors } = this.state;

    return (
      <React.Fragment>
        <AppAppBar />
        <AppForm>
          <React.Fragment>
            <Typography variant="h4" gutterBottom marked="center" align="center">
              Driver Application Form
            </Typography>
            <Typography variant="body2" align="center">
              <Link href="/consent-form" underline="always">
                Back
              </Link>
              &nbsp;
              &nbsp;
              <Link href="/" underline="always">
                Home
              </Link>
            </Typography>

          </React.Fragment>

          {/* Import Form */}
          <DriverDetailsForm
            loading={this.state.loading}
            submitCb={this.Send}
            errors={errors}
            SetImage={this.SetImage}
            SetImage2={this.SetImage2}
            SetImage3={this.SetImage3}
            pristine={this.pristine}
            key={this.state.key}
          />
          {/* <Form
            onSubmit={handleSubmit}
            subscription={{ submitting: true }}
            validate={validate}
          >
            {({ handleSubmit: handleSubmit2, submitting }) => (
              <Box component="form" onSubmit={handleSubmit2} noValidate sx={{ mt: 6 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      autoFocus
                      component={RFTextField}
                      disabled={submitting || sent}
                      autoComplete="given-name"
                      fullWidth
                      label="Driver First name"
                      name="firstName"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={RFTextField}
                      disabled={submitting || sent}
                      autoComplete="family-name"
                      fullWidth
                      label="Driver Last name"
                      name="lastName"
                      required
                    />
                  </Grid>
                </Grid>
                <Field
                  component={RFTextField}
                  disabled={submitting || sent}
                  fullWidth
                  label="Date of submission (E.g: dd/mm/yyyy)"
                  margin="normal"
                  name="submissionDate"
                  required
                />
                <Field
                  component={RFTextField}
                  disabled={submitting || sent}
                  fullWidth
                  label="ID Number"
                  margin="normal"
                  name="idNumber"
                  required
                />
                <Field
                  component={RFTextField}
                  disabled={submitting || sent}
                  fullWidth
                  label="Phone Number"
                  margin="normal"
                  name="phoneNumber"
                  required
                />
                <Field
                  component={RFTextField}
                  disabled={submitting || sent}
                  fullWidth
                  label="Email Address"
                  margin="normal"
                  name="email"
                  required
                />
                <Field
                  component={RFTextField}
                  disabled={submitting || sent}
                  fullWidth
                  label="Mpesa Transaction Code (Eg: PLK1234567TY)"
                  margin="normal"
                  name="mpesaTransactionCode"
                  required
                />
                <hr />

                <Field
                  name="passportPhoto"
                  disabled={submitting || sent}
                  fullWidth
                  margin="normal"
                  required
                >
                  {({ input, meta }) => (
                    <div>
                      <label>Upload Passport Photo:</label>
                      <br />
                      <br />
                      <input
                        type="file" {...input}
                        accept=".jpg, .png, .jpeg"
                      // onChange={onChange}
                      />
                      {meta.touched && meta.error && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>

                <hr />
                <Field
                  name="idPhoto"
                  disabled={submitting || sent}
                  fullWidth
                  margin="normal"
                  required
                >
                  {({ input, meta }) => (
                    <div>
                      <label>Upload ID Photo:</label>
                      <br />
                      <br />
                      <input
                        type="file" {...input}
                        accept=".jpg, .png, .jpeg"
                      // onChange={onChange}
                      />
                      {meta.touched && meta.error && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>

                <hr />
                <Field
                  name="goodConductCertPhoto"
                  disabled={submitting || sent}
                  fullWidth
                  margin="normal"
                  required
                >
                  {({ input, meta }) => (
                    <div>
                      <label>Upload Good Conduct Certificate Photo:</label>
                      <br />
                      <br />
                      <input
                        type="file" {...input}
                        accept=".jpg, .png, .jpeg"
                      // onChange={onChange}
                      />
                      {meta.touched && meta.error && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>

                <hr />

                <FormSpy subscription={{ submitError: true }}>
                  {({ submitError }) =>
                    submitError ? (
                      <FormFeedback error sx={{ mt: 2 }}>
                        {submitError}
                      </FormFeedback>
                    ) : null
                  }
                </FormSpy>
                <FormButton
                  sx={{ mt: 3, mb: 2 }}
                  disabled={submitting || sent}
                  color="secondary"
                  fullWidth
                >
                  {submitting || sent ? 'Sending…' : 'Send'}
                </FormButton>
              </Box>
            )}
          </Form> */}
        </AppForm>
        <AppFooter />
      </React.Fragment>
    );
  }
}

export default (connect(null, null))(withRoot(ApplicationFormPage));
