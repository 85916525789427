import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from './modules/components/Typography';
import AppFooter from './modules/views/AppFooter';
import AppAppBar from './modules/views/AppAppBar';
import AppForm from './modules/views/AppForm';
import withRoot from './modules/withRoot';

function ApplicationSentFailPage() {

  return (
    <React.Fragment>
      <AppAppBar />
      <AppForm>
        <React.Fragment>
          <Typography variant="h4" gutterBottom marked="center" align="center">
            Application Send Failed
          </Typography>
          <Typography variant="body1" align="center">
            {'Sorry, an error occured on submitting your details for screening. Kindly make sure you have entered correct information on the form.'}
            <br/>
            <br/>
            {'Kind Regards, Peleza International.'}
            <br/>
            <br/>
            {'Ensure to attach only images with the correct image formart(png, jpg or jpeg) and of small sizes (kb). Kindly, '}
            <br/>
            <br/>
            <Link
              href="/application-form"
              align="center"
              underline="always"
            >
              Try Again.
            </Link>
          </Typography>
        </React.Fragment>

        {/* <br/>
        <br/>
        <Typography align="center">
          <Link underline="always" href="/">
            Back Home
          </Link>
        </Typography> */}
      </AppForm>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(ApplicationSentFailPage);
