import React, { Component } from 'react';
import { Provider } from 'react-redux';
import './App.css';
import LandingPage from './LandingPage/index';
import ConsentPage from './LandingPage/ConsentPage';
import ApplicationFormPage from './LandingPage/ApplicationFormPage';
import ApplicationSentPage from './LandingPage/ApplicationSentPage';
import ApplicationSentFailPage from './LandingPage/ApplicationSentFailPage';

import { Routes, Route } from 'react-router-dom';


const store = require('./reducers').init();
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <div className='Container'>
          <Routes>
            <Route exact path='/' element={<LandingPage />} />
            <Route path='/consent-form' element={<ConsentPage />} />
            <Route path='/application-form' element={<ApplicationFormPage />} />
            <Route path='/application-sent-page' element={<ApplicationSentPage />} />
            <Route path='/application-sent-fail-page' element={<ApplicationSentFailPage />} />
          </Routes>

        </div>
      </Provider>
    );
  }
}
export default App;