import React from 'react';
import Home from './Home';

function index() {
  return <div>
      <Home/>
  </div>;
}

export default index;
