import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from './modules/components/Typography';
import AppFooter from './modules/views/AppFooter';
import AppAppBar from './modules/views/AppAppBar';
import AppForm from './modules/views/AppForm';

import FormButton from './modules/form/FormButton';

import withRoot from './modules/withRoot';
import ConsentForm from '../Components/ConsentForm';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';

function ConsentPage() {
  const navigate = useNavigate();

  const [checked, setChecked] = React.useState(false)

  const handleClick = () => {
    setChecked(!checked)
    console.log(!checked)
  }

  return (
    <React.Fragment>
      <AppAppBar />
      <AppForm>
        <React.Fragment>
          <Typography variant="h4" gutterBottom marked="center" align="center">
            Driver Application Form
          </Typography>

        </React.Fragment>

        <Box component="form" noValidate sx={{ mt: 6 }}>

          <ConsentForm />

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="checkAddress"
                  value="yes"
                  onClick={handleClick}
                  checked={checked}
                  type="checkbox"
                />
              }
              label="Please Check the Box to Consent"

            />
          </Grid>

          <FormButton
            sx={{ mt: 3, mb: 2 }}
            size="large"
            color="secondary"
            fullWidth
            onClick={() => { navigate('/application-form') }}
            disabled={!checked}
          >
            Next
          </FormButton>
        </Box>
      </AppForm>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(ConsentPage);
