import * as React from 'react';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import {useNavigate} from 'react-router-dom';

const backgroundImage =
  'https://images.unsplash.com/photo-1600320254374-ce2d293c324e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80';

export default function ProductHero() {
  const navigate = useNavigate();

  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'right',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Typography color="inherit" align="center" variant="h4" marked="center">
        Moove Africa Background Check
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { sx: 4, sm: 10 } }}
      >
        To apply or renew your Driver Background Check Report please click on the Button below, consent to a Background Check and fill in all details and submit your request.
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        // href="/premium-themes/onepirate/sign-up/"
        onClick={() => {navigate('/consent-form')}}
        sx={{ minWidth: 200 }}
      >
        Verify Driver
      </Button>
      <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
        New Vehicle Driver Verification
      </Typography>
    </ProductHeroLayout>
  );
}
