import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from './modules/components/Typography';
import AppFooter from './modules/views/AppFooter';
import AppAppBar from './modules/views/AppAppBar';
import AppForm from './modules/views/AppForm';
import withRoot from './modules/withRoot';

function ApplicationSentPage() {

  return (
    <React.Fragment>
      <AppAppBar />
      <AppForm>
        <React.Fragment>
          <Typography variant="h4" gutterBottom marked="center" align="center">
            Application Sent Successfully
          </Typography>
          <Typography variant="body1" align="center">
            {'Thank you for submitting your details for Screening.  We will respond to you via email within the next 48 hours. '}
            <br/>
            <br/>
            {'Kind Regards, Peleza International.'}
            <br/>
            <br/>
            {'If you would like to submit another request. Kindly, '}
            <Link
              href="/consent-form"
              align="center"
              underline="always"
            >
              Click Here.
            </Link>
          </Typography>
        </React.Fragment>

        <br/>
        <br/>
        <Typography align="center">
          <Link underline="always" href="/">
            Back Home
          </Link>
        </Typography>
      </AppForm>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(ApplicationSentPage);
